require(['jquery', 'js/glider-min'], function ($) {
const heroSliders = document.querySelectorAll(".js-hero-slider");

if (heroSliders) {
	heroSliders.forEach(heroSlider => {
		const glider = new Glider(heroSlider, {
			slidesToShow: 1,
			slidesToScroll: 1,
			draggable: true,
			scrollLock: true,
			rewind: true,
			arrows: {
				prev: ".js-hero-slider-prev",
				next: ".js-hero-slider-next"
			},
			dots: '.js-hero-slider-bullets'
		});

		glider.isLastSlide = function() {
			return slider.page >= slider.dots.childElementCount - 1;
		}

		let autoplayDelay = 3000;

		let timeout = -1;
		let hovering = false;
		function startTimeout() {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (!hovering)
					glider.scrollItem((glider.slide + 1) % glider.slides.length);
			}, autoplayDelay);
		}

		let animID = 0;
		const isAnimating = () => glider.animate_id !== animID;

		heroSlider.addEventListener("glider-animated", function (event) {
				animID = glider.animate_id;
				if (!hovering) startTimeout();
			});

		heroSlider.addEventListener('mouseover', (event) => {
			hovering = true;
			clearTimeout(timeout);
		});

		startTimeout();
	})
}
	window.addEventListener('load', (event) => {
		function hideArrowsAndBulletsIfSingleItem() {
			if (!heroSliders) {
				return;
			}
			const heroSliderItems = document.querySelectorAll('.c-hero-slider__item');
			const arrowsElements = document.querySelectorAll('.c-hero-slider__arrows');
			const bulletsElement = document.querySelector('.c-hero-slider__bullets');

			if (heroSliderItems.length === 1) {
				arrowsElements.forEach(element => {
					element.style.display = 'none';
				});

				if (bulletsElement) {
					bulletsElement.style.display = 'none';
				}
			}
		}
		hideArrowsAndBulletsIfSingleItem();
	})

});

